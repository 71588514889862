import {
  CameraCenterData,
  centerOrthoCamera,
  useCameraRotationFor2dOverviewAndMiniMap,
  useCenterCameraOnPlaceholders,
  useOrthoCameraRotationFromSheet,
} from "@/hooks/use-center-camera-on-placeholders";
import { useCurrentAreaClippingBox } from "@/hooks/use-object-bounding-box";
import { ModeTransitionProps } from "@/modes/mode";
import { useCached3DObjectsIfExists } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { perspCameraDistance } from "@/utils/persp-camera-distance";
import { selectIElementWorldPosition } from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { useEffect, useMemo } from "react";
import { OrthographicCamera, Vector3 } from "three";
import { SheetRenderer } from "../renderers/sheet-renderer";
import { CameraAnimation } from "./camera-animation";

interface ToSheetAnimationProps extends ModeTransitionProps {
  /** Placeholders which are being rendered on top of the sheet */
  placeholders: IElementImg360[];

  /** The sheets we are transitioning to */
  sheetElements: IElementGenericImgSheet[];

  /** The sheet used to set camera orientation */
  activeSheetFor2DNavigation?: IElementGenericImgSheet;

  /**
   * Indicates if the 2D overview orientation setting (project or sheet)
   * should be used to set the camera orientation. When set to true, the orientation
   * computed using activeSheetFor2DNavigation will be ignored.
   */
  use2dOverviewOrientation?: boolean;
}

/**
 *
 * @returns the transition to enter into a mode with sheet and placeholders
 */
export function ToSheetAnimation({
  defaultCamera,
  modeCamera,
  previousMode,
  onCompleted,
  placeholders,
  sheetElements,
  activeSheetFor2DNavigation,
  use2dOverviewOrientation,
}: ToSheetAnimationProps): JSX.Element {
  const areaVolume = useCurrentAreaClippingBox();
  const activeSheetCameraRotation = useOrthoCameraRotationFromSheet(
    activeSheetFor2DNavigation?.id,
  );
  const cameraRotationFor2dOverview =
    useCameraRotationFor2dOverviewAndMiniMap();
  const centeringData = useCenterCameraOnPlaceholders({
    areaVolume,
    sheetElements,
    cameraRotation: use2dOverviewOrientation
      ? cameraRotationFor2dOverview
      : activeSheetCameraRotation,
    placeholders,
  });

  return (
    <ToSheetAnimationBase
      defaultCamera={defaultCamera}
      modeCamera={modeCamera}
      sheetElements={sheetElements}
      previousMode={previousMode}
      centeringData={centeringData}
      onCompleted={onCompleted}
    />
  );
}

type ToSheetAnimationBaseProps = Pick<
  ToSheetAnimationProps,
  | "defaultCamera"
  | "modeCamera"
  | "sheetElements"
  | "previousMode"
  | "onCompleted"
> & {
  /** The centering data to position the camera on */
  centeringData: CameraCenterData;
};

/**
 * @returns the transition to enter into a mode with a given centeringData
 */
export function ToSheetAnimationBase({
  defaultCamera,
  modeCamera,
  sheetElements,
  previousMode,
  centeringData,
  onCompleted,
}: ToSheetAnimationBaseProps): JSX.Element {
  const sheets = useCached3DObjectsIfExists(sheetElements);
  // We assume all sheets have the same height
  const activeSheetHeight = useAppSelector(
    selectIElementWorldPosition(sheetElements[0]?.id),
  )[1];

  // The camera for the transition is a perspective camera, but then will be swapped to orthographic.
  // We set the target frustum for our camera in such a way that it lines up with the orthographic
  // camera exactly at the position of the sheet.
  // This avoids "jumps" when the camera is switched.
  const targetPosition = useMemo(() => {
    const orthoFrustum = centeringData.frustum;
    const distance = perspCameraDistance(
      orthoFrustum.top - orthoFrustum.bottom,
      defaultCamera.getEffectiveFOV(),
    );
    const center = centeringData.position;

    return new Vector3(center.x, activeSheetHeight + distance, center.z);
  }, [activeSheetHeight, centeringData, defaultCamera]);

  // Pre-adjust mode camera so when transition ends we don't have jumps
  useEffect(() => {
    if (!(modeCamera instanceof OrthographicCamera)) return;
    centerOrthoCamera(modeCamera, centeringData);
  }, [centeringData, modeCamera]);

  // Remove the camera animation if the app just started
  const duration = useMemo(
    () => (previousMode === "start" ? 0 : 1),
    [previousMode],
  );

  return (
    <>
      <CameraAnimation
        position={targetPosition}
        quaternion={centeringData.quaternion}
        duration={duration}
        onAnimationFinished={onCompleted}
      />
      {sheets.map((sheet) => (
        <SheetRenderer transparent sheet={sheet} key={sheet.iElement.id} />
      ))}
    </>
  );
}
