import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import { ToPanoAnimation } from "@/components/r3f/animations/to-pano-animation";
import { EnvMapBackgroundRenderer } from "@/components/r3f/renderers/env-map-renderer";
import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import { useCameraParametersIfAvailable } from "@/components/r3f/utils/camera-parameters";
import {
  MiniMap,
  MiniMapActions,
  MiniMapOverlay,
} from "@/components/r3f/utils/minimap";
import { ProjectOverviewBase } from "@/components/ui/project-overview";
import { SceneContextMenu } from "@/components/ui/scene-context-menu";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import {
  useOverlayElements,
  useOverlayRef,
} from "@/modes/overlay-elements-context";
import { usePreload3DObjects } from "@/object-cache";
import { setActiveCad } from "@/store/cad/cad-slice";
import { selectIsMinimapFullScreen } from "@/store/minimap-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectWalkMainSceneType,
  setShouldUseIntensityData,
  setWalkSceneFilter,
} from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { useDeactivateToolOnUnmount } from "@/tools/use-toggle-tool-visibility";
import { SceneFilter } from "@/types/scene-filter";
import { getCameraAnimationTime } from "@/utils/camera-animation-time";
import {
  View,
  selectChildDepthFirst,
  selectHasValidPose,
  selectIElement,
  useEnvironmentMap,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementImg360,
  isIElementImg360,
  isIElementModel3dStream,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import { Vector3 as Vector3Prop, useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Vector3 } from "three";
import {
  Mode,
  ModeTransitionProps,
  ModeWithSceneFilterInitialState,
  SceneFilterLookAtInitialState,
} from "../mode";
import { selectSheetForElement } from "../mode-selectors";
import { selectBestModelCameraFor360 } from "./animations/pano-to-model";
import { To3dWalkAnimation } from "./animations/to-3d-walk-animation";
import { useWalkModeToolVisibility } from "./hooks/use-walk-mode-tool-visibility";
import { WalkOverlay, WalkOverlayBottomButtons } from "./walk-overlay";
import { WalkScene } from "./walk-scene";
import { selectWalkSceneElements } from "./walk-scene-selectors";
import {
  WALK_MODE_INITIAL_STATE,
  useComputeValidWalkScene,
} from "./walk-state";
import { ViewType } from "./walk-types";

function Fallback(): JSX.Element {
  const { envMap } = useEnvironmentMap();

  return <EnvMapBackgroundRenderer texture={envMap} />;
}

/**
 * Object to contain Walk Mode functionality.
 * That is, providing visualization of data types represented by @see SceneFilter
 * And the ability to traverse that data in an intuitive walking like way.
 */
export const walkMode: Mode<SceneFilterLookAtInitialState> = {
  name: "walk",

  initialState: WALK_MODE_INITIAL_STATE,

  ModePanel: ProjectOverviewBase,

  ModeScene() {
    const store = useAppStore();
    const dispatch = useAppDispatch();

    const currentScene = useCurrentScene();
    const currentArea = useCurrentArea();

    const walkSceneFilter = useAppSelector(selectWalkMainSceneType);

    const { cameras } = useViewRuntimeContext();

    const [main, overlayElement] = useAppSelector(
      selectWalkSceneElements(
        currentScene,
        currentArea,
        cameras[0].position,
        walkSceneFilter,
      ),
      isEqual,
    );
    assert(main, "We need a valid main element to render in walk mode");

    useWalkModeToolVisibility(main, walkSceneFilter);
    useDeactivateToolOnUnmount();

    // Load resources
    usePreload3DObjects([main, ...currentScene.activeSheets]);

    // Mini map data
    const { miniMap, minimapCanvas } = useOverlayElements();
    const miniMapActions = useRef<MiniMapActions>(null);
    const camera = useThree((s) => s.camera);
    const [cameraPosition, setCameraPosition] = useState<Vector3Prop>(
      camera.position,
    );
    const [targetCameraPosition, setTargetCameraPosition] =
      useState<Vector3Prop>();

    /** This function animates the camera to a new position. The camera locator on the minimap is animated as well. */
    const moveCameraTo = useCallback((p: Vector3) => {
      setCameraPosition(p);
      setTargetCameraPosition(p);
    }, []);

    const clearCameraAnimation = useCallback(() => {
      setTargetCameraPosition(undefined);
    }, []);

    const changeActiveElement = useCallback(
      (element: IElement) => {
        if (isIElementModel3dStream(element)) {
          dispatch(setActiveCad(element.id));
        } else {
          dispatch(setActiveElement(element.id));
        }
      },
      [dispatch],
    );

    const setActiveElementOnWayPoint = useCallback(
      (t: WayPointTarget) => {
        // Set the active element to the target pano only if the user is in pano mode
        if (walkSceneFilter === SceneFilter.Pano) {
          dispatch(setActiveElement(t.targetElement.id));
        }
      },
      [dispatch, walkSceneFilter],
    );

    const showUserMarkerInMinimap = useAppSelector(
      (state) => !isIElementImg360(main) || selectHasValidPose(main)(state),
    );
    const showPlaceholders =
      !isIElementImg360(main) || !!main.isRotationAccurate;
    const showViewConeInMinimap =
      !isIElementImg360(main) || !!main.isRotationAccurate;

    // Build scene
    return (
      <>
        <View>
          <Suspense fallback={<Fallback />}>
            <WalkScene
              activeElement={main}
              overlayElement={
                overlayElement && isIElementModel3dStream(overlayElement)
                  ? overlayElement
                  : undefined
              }
              paths={currentScene.paths}
              sheetForElevation={currentScene.activeSheetFor2DNavigation}
              placeholders={currentScene.panos}
              shouldShowPlaceholders={showPlaceholders}
              annotations={currentScene.annotations}
              onCameraMovedViaAnimation={setCameraPosition}
              onUserWalkedTo={moveCameraTo}
              onActiveElementChanged={changeActiveElement}
              viewType={ViewType.MainView}
              onCameraMovedViaControls={(pos) =>
                miniMapActions.current?.centerCameraOn(pos)
              }
              onWayPointChanged={setActiveElementOnWayPoint}
            />
          </Suspense>
          {/* The camera animation component is placed as child of <View />, because View opens
           * another R3F portal. In this way the camera animation can get the walk mode controls
           * from the useThree hook and communicate to the controls that the camera has a new
           * pose at the end of the animation. */}
          {targetCameraPosition && (
            <CameraAnimation
              position={targetCameraPosition}
              duration={getCameraAnimationTime(camera, targetCameraPosition)}
              onAnimationFinished={clearCameraAnimation}
            />
          )}
        </View>
        {miniMap && (
          <MiniMap
            actions={miniMapActions}
            canvasElement={minimapCanvas}
            trackingElement={miniMap}
            sheetElements={currentScene.activeSheets}
            activeSheetForElevation={
              currentScene.activeSheetFor2DNavigation ??
              currentScene.activeSheets[0]
            }
            camera={camera}
            cameraPosition={cameraPosition}
            showUserMarker={showUserMarkerInMinimap}
            shouldShowViewCone={showViewConeInMinimap}
            onPlaceholderClicked={(el) => {
              dispatch(setActiveElement(el.id));
              if (walkSceneFilter !== SceneFilter.Pano) {
                // in case of point cloud or cad walk mode we animate the camera to the waypoint position
                const position = selectBestModelCameraFor360(
                  el,
                  currentScene.activeSheetFor2DNavigation,
                )(store.getState());
                // Animate the camera to the new position
                moveCameraTo(position);
              }

              // After the user select something on the minimap shrink it if it's full screen
              miniMapActions.current?.shrink();
            }}
            onMinimapClicked={(pos: Vector3) => {
              if (walkSceneFilter !== SceneFilter.Pano) {
                // Keep the camera height as it is
                pos.y = camera.position.y;
                moveCameraTo(pos);
              }
            }}
          />
        )}
      </>
    );
  },

  ModeOverlay(): JSX.Element | null {
    const currentScene = useCurrentScene();
    const dispatch = useAppDispatch();
    const isFullScreen = useAppSelector(selectIsMinimapFullScreen);
    const activeTool = useAppSelector(selectActiveTool);
    const sceneFilter = useAppSelector(selectWalkMainSceneType);

    const onSceneFilterChanged = useCallback(
      (type: SceneFilter) => {
        dispatch(setWalkSceneFilter(type));
      },
      [dispatch],
    );

    const onActiveElementChanged = useCallback(
      (id: GUID) => {
        dispatch(setActiveElement(id));
      },
      [dispatch],
    );

    const onPanoTypeChanged = useCallback(
      (showIntensity: boolean, siblingPano: IElementImg360) => {
        dispatch(setShouldUseIntensityData(showIntensity));
        dispatch(setActiveElement(siblingPano.id));
      },
      [dispatch],
    );

    const { setMiniMap, setMiniMapCanvas } = useOverlayElements();
    const miniMapRef = useOverlayRef<HTMLDivElement>((r) => {
      setMiniMap(r);
    });
    const miniMapCanvasRef = useOverlayRef<HTMLCanvasElement>(setMiniMapCanvas);

    const activeElement =
      sceneFilter === SceneFilter.Cad ? currentScene.cad : currentScene.main;

    const forceMinimizingMinimap = activeTool === ToolName.annotation;

    // Use the name of the first visible layer for minimap's title.
    const singleSheetForMinimapTitle = currentScene.activeSheets[0];

    return (
      <>
        {!isFullScreen && (
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            {activeElement && (
              <WalkOverlay
                sceneFilter={sceneFilter}
                cad={currentScene.cad}
                hasPanos={
                  !!currentScene.panos.length || !!currentScene.paths.length
                }
                activeWalkElement={activeElement}
                referenceElement={currentScene.referenceElement}
                isMeasuring={activeTool === ToolName.measurement}
                onSceneFilterChanged={onSceneFilterChanged}
                onActiveElementChanged={onActiveElementChanged}
                onPanoTypeChanged={onPanoTypeChanged}
              />
            )}
            <Stack direction="row" justifyContent="center">
              <WalkOverlayBottomButtons
                activeElement={currentScene.main}
                walkSceneFilter={sceneFilter}
              />
            </Stack>
            <SceneContextMenu />
          </Stack>
        )}

        <MiniMapOverlay
          eventDivRef={miniMapRef}
          canvasRef={miniMapCanvasRef}
          activeSheetName={singleSheetForMinimapTitle.name}
          forceMinimized={forceMinimizingMinimap}
        />
      </>
    );
  },

  ModeTransition({
    previousMode,
    onCompleted,
    initialState,
    modeCamera,
    ...rest
  }: ModeTransitionProps<ModeWithSceneFilterInitialState>) {
    const dispatch = useAppDispatch();
    const main = useComputeValidWalkScene();
    assert(main, "We need a valid main element to render in walk mode");

    // If the previous mode was split mode there's nothing to animate on the main view
    const canSkipAnimation = previousMode === "split";
    useEffect(() => {
      if (canSkipAnimation) {
        onCompleted();
      }
    }, [canSkipAnimation, onCompleted]);

    useCameraParametersIfAvailable(
      modeCamera,
      initialState?.camera,
      onCompleted,
    );

    useLayoutEffect(() => {
      if (initialState?.scene) {
        dispatch(setWalkSceneFilter(initialState.scene));
      }
    }, [dispatch, initialState]);

    if (canSkipAnimation || (initialState && initialState.camera)) {
      return <SnapshotRenderer />;
    }

    return (
      <ToWalkTransition
        onCompleted={onCompleted}
        initialState={initialState}
        modeCamera={modeCamera}
        previousMode={previousMode}
        {...rest}
      />
    );
  },

  canBeStartedWith(activeElement, state) {
    const activePano = selectChildDepthFirst(
      activeElement,
      isIElementImg360,
    )(state);
    const activePointCloud = selectChildDepthFirst(
      activeElement,
      isIElementPointCloudStream,
    )(state);

    const referenceElement = activePano ?? activePointCloud;
    if (!referenceElement) {
      return false;
    }

    return !!selectSheetForElement(referenceElement)(state);
  },
};

/** @returns a transition from a generic mode to WalkMode */
function ToWalkTransition({
  previousMode,
  onCompleted,
  initialState,
}: ModeTransitionProps<SceneFilterLookAtInitialState>): JSX.Element | null {
  const currentArea = useCurrentArea();

  const { activeSheetFor2DNavigation } = useCurrentScene();

  const dispatch = useAppDispatch();

  const walkActiveElement = useComputeValidWalkScene(() =>
    dispatch(changeMode("sheet")),
  );

  const isPano = walkActiveElement && isIElementImg360(walkActiveElement);

  // If the user selects a pano from the project tree, and the walk scene
  // filter is not SceneFilter.Pano, it needs to be set to SceneFilter.Pano
  useEffect(() => {
    if (isPano) {
      dispatch(setWalkSceneFilter(SceneFilter.Pano));
    }
  }, [walkActiveElement, isPano, dispatch]);

  const shouldRotateCamera = previousMode !== "split";

  const lookAt = useAppSelector(selectIElement(initialState?.lookAtId ?? ""));

  if (!walkActiveElement) {
    return null;
  }

  if (isPano) {
    return (
      <ToPanoAnimation
        key={walkActiveElement.id}
        panoElement={walkActiveElement}
        sheetForElevation={activeSheetFor2DNavigation}
        shouldRotateCamera={shouldRotateCamera}
        onAnimationFinished={onCompleted}
        duration={1}
        lookAt={lookAt}
      />
    );
  }

  return (
    <To3dWalkAnimation
      currentArea={currentArea}
      onCompleted={onCompleted}
      lookAtModel={previousMode === "sheet"}
    />
  );
}
