import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectOrientationOf2dOverview } from "@/store/view-options/view-options-selectors";
import {
  OrientationOf2dOverviewOptions,
  setOrientationOf2dOverview,
} from "@/store/view-options/view-options-slice";
import { Dropdown, FaroText, Option } from "@faro-lotv/flat-ui";
import { validateEnumValue } from "@faro-lotv/foundation";
import { Stack } from "@mui/material";

const dropdownOptions: Option[] = [
  {
    key: OrientationOf2dOverviewOptions.sheet,
    value: OrientationOf2dOverviewOptions.sheet,
    label: OrientationOf2dOverviewOptions.sheet,
    isDisabled: false,
  },
  {
    key: OrientationOf2dOverviewOptions.project,
    value: OrientationOf2dOverviewOptions.project,
    label: OrientationOf2dOverviewOptions.project,
    isDisabled: false,
  },
];

/** @returns An UI element with labels and dropdown providing 2D overview orientation options to the user */
export function OrientationOf2dOverviewMenu(): JSX.Element {
  const orientationOption = useAppSelector(selectOrientationOf2dOverview);
  const dispatch = useAppDispatch();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <FaroText variant="heading14" dark>
          2D overview orientation
        </FaroText>
      </Stack>
      <Dropdown
        dark
        variant="underlined"
        fullWidth={false}
        shouldCapitalize={false}
        options={dropdownOptions}
        value={orientationOption}
        onChange={(e) => {
          const option = e.target.value;
          if (validateEnumValue(option, OrientationOf2dOverviewOptions)) {
            dispatch(setOrientationOf2dOverview(option));
          }
        }}
      />
    </Stack>
  );
}
